.fixed-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333; /* Example background color */
    color: white; /* Example text color */
    text-align: center; /* Example text alignment */
    padding: 10px 0; /* Example padding */
}

.fixed-bottom button{color: white;border:1px solid #fff;padding:10px}

.testtab{border: 1px solid #ccc;}
.testtab:hover{background-color: "#333";border: 1px solid #333;}