.modal-backdrop {
    position: fixed !important;
    top: 0  !important;
    left: 0  !important;
    width: 100%  !important;
    height: 100%  !important;
    background-color: rgba(0, 0, 0, 0.5)  !important;
    display: flex  !important;
    justify-content: center  !important;
    align-items: center  !important;
    
  }
  
  .modal-content1{
   
    width: 50%  !important;
    background-color: white  !important;
    padding: 20px  !important;
    border-radius: 5px  !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25)  !important;
    z-index: 999  !important;
  }
  