/* DynamicBoxes.css */
.dynamic-boxes-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  
  .dynamic-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
    min-width: 150px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .box-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .box-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .count {
    font-size: 24px;
    font-weight: bold;
  }
  
  .dropoff-count {
    font-size: 18px;
    color: red;
    display: flex;
    align-items: center;
  }
  
  .dropoff-count::before {
    content: '↓ ';
    margin-right: 5px;
  }
  
  .percentage {
    color: rgb(0, 0, 0);
    margin-top: 5px;
  }
  