.user-profile {
    display: flex;
    align-items: center;
    padding: 10px;
}

.profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
}

.user-details {
    font-size: 20px;
}
