/* Button styles */
.share-button {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
  }
  
  /* Dialog styles */
  .dialog-paper {
    border-radius: 16px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
    background-color: #f7f9fc;
  }
  
  .dialog-content {
    padding: 20px;
  }
  
  .link-field {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 8px;
  }
  
  /* Icon container */
  .icon-container {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }
  
  /* Dialog actions */
  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
  
  .close-button {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    text-transform: none;
    font-size: 14px;
    font-weight: bold;
  }
  
  .close-button:hover {
    background-color: #d32f2f;
  }
  