/* DashboardLayout.css */
.dashboard-layout {
    display: flex;
    height: 100vh;
  }
  
  .vertical-menu {
    width: 200px;
    background-color: #333;
    color: white;
  }
  
  .vertical-menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .vertical-menu ul li a {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
  }
  
  .vertical-menu ul li a:hover {
    background-color: #444;
  }
  
  .main-area {
    flex-grow: 1;
  }
  
  .top-menu {
    background-color: #eee;
    padding: 10px;
    display: flex;
  }
  
  .top-menu ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .top-menu ul li {
    padding: 0 10px;
  }
  
  .top-menu ul li a {
    text-decoration: none;
    color: #333;
  }
  
  .content-area {
    padding: 20px;
  }
  