.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 800px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .popup h2 {
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
  }
  
  .filter-input,
  .message-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .message-input {
    resize: none; /* Ensure the text area does not resize */
  }
  
  .results {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .item {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .item h3 {
    margin: 0;
    margin-left: 10px;
  }
  
  .item p {
    margin: 5px 0;
  }
  
  .close-popup {
    background: none;
    border: none;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  
  .close-popup:hover {
    color: red;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center; /* Center the compose button as the close button is removed */
  }
  
  .compose-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #1976d2;
    color: white;
    width: 100%; /* Full width for better visual impact */
  }
  
  .compose-button:hover {
    background-color: #1565c0;
  }
  